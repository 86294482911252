
import { Options, Vue } from 'vue-class-component';
import { setOptions, bootstrap } from 'vue-gtag';

// SVG
import plusSVG from '@/components/svg/SVGPlus.vue';

// Components
import AppHeader from '@/components/AppHeader.vue';
import Alert from '@/components/Alert.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import UpdateApp from '@/components/UpdateApp.vue';
import UserInfo from '@/components/UserInfo.vue';
import Loading from '@/components/Loading.vue';

@Options({
  name: 'GoTime',
  components: {
    AppHeader,
    Breadcrumbs,
    Alert,
    UpdateApp,
    UserInfo,
    plusSVG,
    Loading,
  },
  data() {
    return {
      alerts: [],
      persistentAlerts: [],
      uin: null,
    };
  },
  created() {
    this.$store.dispatch('info');
    if (process.env.VUE_APP_ANALYTICS) {
      setOptions({
        config: { id: process.env.VUE_APP_ANALYTICS },
      });
      bootstrap().then((gtag) => {
        // all done!
      });
    }
  },
  computed: {
    appVersion() {
      return this.$store.getters.appVersion;
    },
    userInfoData() {
      return this.$store.state.userInfoData;
    },
    loading() {
      const isLoginPage = this.$route.name === 'Login';
      const isEmployeeData = this.userInfoData;
      // const { token } = this.$store.state;
      return !isEmployeeData && !isLoginPage;
    },
    userCalendarsData() {
      return this.$store.state.userCalendarsData;
    },
    alertError() {
      interface Properties {
        type?: string;
        value?: string;
      }
      const error: Properties = {
        type: 'error',
        value: this.$store.state.error,
      };
      return error;
    },
    alertSuccess() {
      interface Properties {
        type?: string;
        value?: string;
      }
      const success: Properties = {
        type: 'success',
        value: this.$store.state.success,
      };
      return success;
    },
    alertWarning() {
      interface Properties {
        type?: string;
        value?: string;
      }
      const warning: Properties = {
        type: 'warning',
        value: this.$store.state.warning,
      };
      return warning;
    },
    persistentAlert() {
      interface Properties {
        type?: string;
        value?: string;
      }
      const persistent: Properties = {
        type: 'persistent',
        value: this.$store.state.persistentAlert,
      };
      return persistent;
    },
  },
  methods: {
    checkEmployee() {
      this.$store.dispatch('userInfo', { uin: null });
      this.$store.dispatch('getCalendars', { uin: null });
    },
    alertTimeout(length: number) {
      const calcLengthTime = length * 50;
      const alertTime = calcLengthTime >= 2000 ? calcLengthTime : 2000;
      const alertTimeMultiple = alertTime + (500 * (this.alerts.length - 1));
      if (this.alerts.length > 1) {
        setTimeout(() => this.alerts.splice(0, 1), alertTimeMultiple);
      } else {
        setTimeout(() => this.alerts.splice(0, 1), alertTime);
      }
    },
  },
  watch: {
    alertError(val) {
      if (this.$store.state.error !== null) {
        this.alerts.push(val);
        this.alertTimeout(val.value.length);
        this.$store.commit('resetError');
      }
    },
    alertSuccess(val) {
      if (this.$store.state.success !== null) {
        this.alerts.push(val);
        this.alertTimeout(val.value.length);
        this.$store.commit('resetSuccess');
      }
    },
    alertWarning(val) {
      if (this.$store.state.warning !== null) {
        this.alerts.push(val);
        this.alertTimeout(val.value.length);
        this.$store.commit('resetWarning');
      }
    },
    persistentAlert(val) {
      // persistent alert loaded in state
      if (this.$store.state.persistentAlert !== null
      && this.$store.state.persistentAlert !== false
      && val.value.remove !== true
      // Making sure alert doesn't already exist / isn't being duplicated.
      && !this.persistentAlerts.some((alerts: any) => alerts.value.message === val.value.message)) {
        this.persistentAlerts.push(val);
        this.$store.commit('resetPersistentAlert');
      } else if (val.value?.remove === true) {
        for (let i = this.persistentAlerts.length - 1; i >= 0; i -= 1) {
          if (
            this.persistentAlerts.some((alerts: any) => alerts.value.message === val.value.message)
            && this.persistentAlerts.some((alerts: any) => alerts.value.type === val.value.type)
          ) {
            this.persistentAlerts.splice(i, 1);
          }
        }
      }
    },
    '$store.state.token': {
      immediate: true,
      handler(val) {
        if (val) {
          this.checkEmployee();
        }
      },
    },
    'userInfoData.persistentAlerts': {
      handler(val) {
        if (val && val.length >= 0) {
          val.forEach((alert: any) => {
            this.$store.commit('updatePersistentAlert', {
              message: alert.message,
            });
          });
        }
      },
    },
    'userInfoData.validationErrors': {
      handler(val) {
        if (val && val.length >= 0) {
          val.forEach((alert: any) => {
            this.$store.commit('updateErrorAlert', {
              message: alert.message,
            });
          });
        }
      },
    },
  },
})
export default class App extends Vue {}
