
import { Options, Vue } from 'vue-class-component';
import moment from 'moment';

import LeaveList from '@/components/LeaveList.vue';
import MultiSelectList from '@/components/MultiSelectList.vue';
import UserInfo from '@/components/UserInfo.vue';
import Loading from '@/components/Loading.vue';
import Error from '@/components/Error.vue';

@Options({
  name: 'Manage Single Employee',
  components: {
    LeaveList,
    MultiSelectList,
    UserInfo,
    Loading,
    Error,
  },
  props: [
    'isHRAdminReadOnly',
  ],
  data() {
    return {
      leavePendingKey: 0,
      leaveProcessedKey: 0,
    };
  },
  created() {
    if (this.payload.uin) {
      this.checkEmployee();
      this.dispatchPendingRequests();
      this.dispatchProcessedRequests();
    }
  },
  computed: {
    payload() {
      return { uin: this.$route.params.uin };
    },
    lookUpUserInfoData() {
      return this.$store.state.lookUpUserInfoData;
    },
    userCalendarsData() {
      return this.$store.state.calendarsData;
    },
    employeePendingRequestsData() {
      return this.$store.state.employeePendingRequestsData;
    },
    employeeProcessedRequestsData() {
      return this.$store.state.employeeProcessedRequestsData;
    },
  },
  methods: {
    checkEmployee() {
      this.$store.dispatch('userInfo', this.payload);
      this.$store.dispatch('getCalendars', this.payload);
    },
    dispatchPendingRequests() {
      this.$store.dispatch('employeePendingRequests', this.payload);
    },
    dispatchProcessedRequests() {
      this.$store.dispatch('employeeProcessedRequests', this.payload);
    },
    approveRequest(uuids: any, enableButton: () => void, reviewerComment: string) {
      const payload = {
        uuids,
        reviewerComment,
        uin: this.payload,
        enableButton,
      };
      this.$store.dispatch('approveRequest', payload);
    },
    declineRequest(uuids: any, enableButton: () => void, reviewerComment: string) {
      const payload = {
        uuids,
        reviewerComment,
        uin: this.payload,
        enableButton,
      };
      this.$store.dispatch('declineRequest', payload);
    },
    deleteDeclined(uuid: string, enableButton: () => void) {
      const payload = {
        uuid,
        uin: this.payload,
        enableButton,
      };
      this.$store.dispatch('deleteDeclined', payload);
    },
    hrViewLeave(uuid: any) {
      this.$router.replace(`/hr-admin/user/${this.$route.params.uin}/${uuid}`);
      this.$store.commit('updateHrUserTab', 'leave-item');
    },
    formatDate(datetime: Date) {
      const newDatetime = moment.utc(datetime, moment.ISO_8601, true).format('MM/DD/YYYY');
      return newDatetime;
    },
    persistentAlert(route: Array<string>, type: string, remove: boolean, message: string) {
      this.$store.commit('updatePersistentAlert', {
        route,
        type,
        remove,
        message,
      });
    },
    employeeConflict(remove: boolean) {
      this.persistentAlert(['Manage Single Employee'], 'Conflict', remove, 'This employee has conflicts in the leave requests, please follow up with the employee to resolve these conflicts by deleting those leave requests and submitting new ones if they wish to. If you were not expecting a schedule update for this employee, please reach out to this employee’s HR Admin.');
    },
  },
  watch: {
    employeePendingRequestsData() {
      // Update commponent
      this.leavePendingKey += 1;
    },
    employeeProcessedRequestsData(val) {
      // Update commponent
      this.leaveProcessedKey += 1;
      // Message if there is a leave with conflict status
      if (val && val.some((leave: any) => leave.status === 'conflict')) {
        this.employeeConflict(false);
      } else {
        this.employeeConflict(true);
      }
    },
    payload(val) { // Used for HR Admin View/Edit User Leaves tab, payload needs to be available
      if (val.uin) {
        this.checkEmployee();
        this.dispatchPendingRequests();
        this.dispatchProcessedRequests();
      }
    },
    '$route.params.uin': {
      handler(val, oldVal) {
        if (val !== oldVal) {
          this.$store.commit('clearEmployeeData');
        }
      },
    },
  },
})
export default class ManageSingleEmployee extends Vue {}
