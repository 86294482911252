
import { Options, Vue } from 'vue-class-component';
import moment from 'moment';

import GroupContainer from '@/components/GroupContainer.vue';
import Button from '@/components/Button.vue';

@Options({
  name: 'Leave Item',
  components: { GroupContainer, Button },
  props: [
    'userInfoData',
    'isHRAdminReadOnly',
  ],
  data() {
    return {
      currentUUID: this.$router.currentRoute.value.params.uuid,
      uin: this.$router.currentRoute.value.params.uin,
      allowHalfDayType: true,
      allowCustomType: true,
      exception: false,
      durationHours: {
        hours: 0,
        minutes: 0,
      },
    };
  },
  created() {
    this.$store.dispatch('userLeaveTypes', { uin: this.uin });
    this.leaveItem();
  },
  computed: {
    leaveItemData() {
      return this.$store.state.leaveItemData;
    },
    leaveTypesData() {
      return this.$store.state.leaveTypesData;
    },
    leaveItemDataAndLeaveTypesData() {
      const item = this.leaveItemData !== null;
      const type = this.leaveTypesData !== null;
      return item && type;
    },
    checkDisabled() {
      let boolean = true;
      if (this.leaveItemData && this.$route.name !== 'View/Edit User') {
        switch (this.leaveItemData.status) {
          case 'declined':
            boolean = false;
            break;
          case 'pending-approval':
          case 'withdraw-approval':
          case 'approved':
          default:
            boolean = true;
        }
      }
      return boolean;
    },
  },
  methods: {
    withdrawRequest(uuid: string, enableButton: () => void) {
      const payload = {
        uuid,
        enableButton,
      };
      this.$store.dispatch('withdrawRequest', payload);
    },
    approveRequest(uuids: string, enableButton: () => void) {
      const payload = {
        uuids: [uuids],
        reviewerComment: this.leaveItemData.reviewer_comment,
        enableButton,
      };
      this.$store.dispatch('approveRequest', payload);
    },
    declineRequest(uuids: string, enableButton: () => void) {
      const payload = {
        uuids: [uuids],
        reviewerComment: this.leaveItemData.reviewer_comment,
        enableButton,
      };
      this.$store.dispatch('declineRequest', payload);
    },
    leaveItem() {
      const payload = {
        uuid: this.currentUUID,
      };
      this.$store.dispatch('leaveItem', payload);
    },
    deleteDeclined(uuid: string, enableButton: () => void) {
      const payload = {
        uuid,
        enableButton,
      };
      this.$store.dispatch('deleteDeclined', payload);
    },
    updateRequest(uuid: string, enableButton: () => void) {
      if (this.leaveItemData.duration_type === 'custom') {
        const hours = parseInt(this.durationHours.hours, 10);
        const minutes = this.durationHours.minutes / 60;
        this.leaveItemData.duration_hours = hours + minutes;
      } else {
        this.leaveItemData.duration_hours = null;
      }
      const payload: {
        uuid: string,
        durationType: string,
        durationHours: number,
        type: string,
        comment: string,
        // uin?: string,
        enableButton: () => void,
      } = {
        uuid,
        durationType: this.leaveItemData.duration_type,
        durationHours: this.leaveItemData.duration_hours,
        type: this.leaveItemData.type,
        comment: this.leaveItemData.submitter_comment,
        enableButton,
      };
      // if (this.$route.name === 'View/Edit User') {
      //   payload.uin = this.$router.currentRoute.value.params.uin;
      // }
      this.$store.dispatch('updateRequest', payload);
    },
    formatDate(date: string) {
      return moment.utc(date).format('YYYY-MM-DD');
    },
    parseStatusTitle(status: string) {
      let parsed;
      switch (status) {
        case 'pending-approval':
          parsed = 'Pending Approval';
          break;
        case 'pending-withdrawal':
          parsed = 'Pending Withdrawal';
          break;
        default:
          parsed = status;
      }
      return parsed;
    },
    leaveChange() {
      // Conditions when user changes leave type in the dropdown
      switch (this.leaveItemData.type) {
        case 'floating_holiday':
        case 'parental_leave':
          this.leaveItemData.duration_type = 'full_day';
          this.allowHalfDayType = false;
          this.allowCustomType = false;
          break;
        case 'compensable_sick':
        case 'plfa':
        case 'plfc':
          this.allowHalfDayType = true;
          this.allowCustomType = true;
          break;
        case 'fmla_float_holiday':
        case 'fmla_parental_leave':
        case 'fmla_vessa_float_holiday':
        case 'vessa_float_holiday':
        case 'fba_float_holiday':
        case 'cba_float_holiday':
          this.leaveItemData.duration_type = 'full_day';
          this.allowHalfDayType = false;
          this.allowCustomType = false;
          this.warningMessagePreapproved(this.leaveItemData.type);
          break;
        case 'fmla_vacation':
        case 'fmla_sick':
        case 'fmla_no_pay':
        case 'accommodation_vacation':
        case 'accommodation_sick':
        case 'accommodation_no_pay':
        case 'vessa_vacation':
        case 'vessa_sick':
        case 'vessa_no_pay':
        case 'fmla_vessa_sick':
        case 'fmla_vessa_vacation':
        case 'fmla_vessa_no_pay':
        case 'military_no_pay':
        case 'workers_comp_no_pay':
        case 'fba_vacation':
        case 'fba_sick':
        case 'fba_no_pay':
        case 'adm':
        case 'od1':
        case 'od2':
        case 'cba_vacation':
        case 'cba_sick':
        case 'cba_unpaid':
        case 'cba_holiday':
        case 'dvl':
        case 'ftl':
        case 'ftd':
        case 'ftu':
        case 'shbn':
          this.allowHalfDayType = true;
          this.allowCustomType = true;
          this.warningMessagePreapproved(this.leaveItemData.type);
          break;
        default:
          this.allowHalfDayType = true;
          this.allowCustomType = this.exception;
      }
      this.userWithDays();
    },
    userWithDays() {
      if (this.userInfoData) {
        switch (this.userInfoData.leaves_value_type) {
          case 'Days':
            this.allowHalfDayType = false;
            this.allowCustomType = false;
            // this.leaveItemData.duration_type = 'full_day';
            break;
          default:
            break;
        }
      }
    },
    warningMessagePreapproved(type: any) {
      let typeParsed = '';
      for (let i = 0; i < this.leaveTypesData.length; i += 1) {
        if (this.leaveTypesData[i].type === type) {
          typeParsed = this.leaveTypesData[i].display_title;
        }
      }
      this.$store.commit(
        'updateWarning',
        `${typeParsed} must be pre-approved by your department Human Resources before submitting the request.`,
      );
    },
    wholeNumber(event: any) {
      if (event.key === '.' || event.key === ',') {
        event.preventDefault();
      }
    },
    async checkMissingType(leaveItemData: any) {
      // If leave item is based on a leave type that was removed from the API, add to dropdown
      if (
        await this.leaveTypesData
        && leaveItemData.type !== this.leaveTypesData.some((e: any) => e.type)
      ) {
        const obj = {
          type: leaveItemData.type,
          display_title: leaveItemData.type_display_title,
          disable: true, // property to disable the option in the dropdown
        };
        this.leaveTypesData.push(obj);
      }
    },
  },
  watch: {
    userInfoData() {
      if (this.userInfoData.type === 'nonExempt') {
        this.exception = true;
        this.leaveChange();
      }
      this.userWithDays();
    },
    leaveItemDataAndLeaveTypesData: {
      handler(val) {
        if (val === true) {
          this.checkMissingType(this.leaveItemData);
          this.leaveChange();
          if (this.leaveItemData.duration_type === 'custom' && this.leaveItemData.duration_hours) {
            const durationHours = this.leaveItemData.duration_hours;
            this.durationHours.hours = Math.floor(durationHours);
            this.durationHours.minutes = 60 * (durationHours - Math.floor(durationHours));
          }
        }
      },
      immediate: true,
    },
    'leaveItemData.type': {
      handler(newVal, oldVal) {
        // When user selects a leave type which allows a Custom duration
        // And the duration was set to Custom
        // Then user goes back to set the leave type to one that doesn't allow Custom duration
        // Set duration to Full Day
        if (this.exception === false
        && newVal !== 'compensable_sick'
        && newVal !== 'fmla_vacation'
        && newVal !== 'fmla_sick'
        && newVal !== 'fmla_no_pay'
        && newVal !== 'accommodation_vacation'
        && newVal !== 'accommodation_sick'
        && newVal !== 'accommodation_no_pay'
        && newVal !== 'vessa_vacation'
        && newVal !== 'vessa_sick'
        && newVal !== 'vessa_no_pay'
        && newVal !== 'fmla_vessa_sick'
        && newVal !== 'fmla_vessa_vacation'
        && newVal !== 'fmla_vessa_no_pay'
        && newVal !== 'military_no_pay'
        && newVal !== 'workers_comp_no_pay'
        && newVal !== 'fba_vacation'
        && newVal !== 'fba_sick'
        && newVal !== 'fba_no_pay'
        && newVal !== 'plfa'
        && newVal !== 'adm'
        && newVal !== 'od1'
        && newVal !== 'od2'
        && newVal !== 'cba_vacation'
        && newVal !== 'cba_sick'
        && newVal !== 'cba_unpaid'
        && newVal !== 'cba_holiday'
        && newVal !== 'dvl'
        && newVal !== 'ftl'
        && newVal !== 'ftd'
        && newVal !== 'ftu'
        && newVal !== 'shbn'
        && newVal !== 'plfc'
        && this.leaveItemData.duration_type === 'custom') {
          switch (oldVal) {
            case 'compensable_sick':
            case 'fmla_vacation':
            case 'fmla_sick':
            case 'fmla_no_pay':
            case 'accommodation_vacation':
            case 'accommodation_sick':
            case 'accommodation_no_pay':
            case 'vessa_vacation':
            case 'vessa_sick':
            case 'vessa_no_pay':
            case 'fmla_vessa_sick':
            case 'fmla_vessa_vacation':
            case 'fmla_vessa_no_pay':
            case 'military_no_pay':
            case 'workers_comp_no_pay':
            case 'fba_vacation':
            case 'fba_sick':
            case 'fba_no_pay':
            case 'plfa':
            case 'adm':
            case 'od1':
            case 'od2':
            case 'cba_vacation':
            case 'cba_sick':
            case 'cba_unpaid':
            case 'cba_holiday':
            case 'dvl':
            case 'ftl':
            case 'ftd':
            case 'ftu':
            case 'shbn':
            case 'plfc':
              this.leaveItemData.duration_type = 'full_day';
              break;
            default:
              break;
          }
        }
      },
      deep: true,
    },
  },
  beforeUnmount() {
    this.$store.commit('clearLeaveItem');
  },
})
export default class LeaveItem extends Vue {}
