
import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'Button',
  props: ['text', 'type', 'ariaLabel', 'to', 'disabled', 'async'],
  data() {
    return {
      internalDisable: false,
    };
  },
  methods: {
    handleClick(event: MouseEvent): void {
      // this is to disable the button during async calls,
      // and allow parent to re-enable on it's own terms
      if (this.async === true && this.internalDisable !== true) {
        event.stopImmediatePropagation(); // prevent function running twice
        this.internalDisable = true; // disable the button
        this.$emit('click', this.enableButton); // allow parent to re-enable the button when ready
      }
    },
    enableButton() {
      this.internalDisable = false;
    },
  },
})
export default class Button extends Vue {}
