
import { Options, Vue } from 'vue-class-component';
import moment from 'moment';

import LeaveList from '@/components/LeaveList.vue';
import NewRequestButton from '@/components/NewRequestButton.vue';

// import { persistentAlert } from '../composables/persistentAlert.ts';

@Options({
  name: 'Home',
  components: {
    LeaveList,
    NewRequestButton,
  },
  props: ['userInfoData'],
  data() {
    return {
      leaveRequestKey: 0,
      leaveHistoryKey: 0,
    };
  },
  created() {
    this.$store.dispatch('userLeaveHistory');
    this.$store.dispatch('userLeaveRequests');
  },
  computed: {
    userLeaveHistoryData() {
      return this.$store.state.userLeaveHistoryData;
    },
    userLeaveRequestsData() {
      return this.$store.state.userLeaveRequestsData;
    },
  },
  methods: {
    withdrawRequest(uuid: string, enableButton: () => void) {
      const payload = {
        uuid,
        enableButton,
      };
      this.$store.dispatch('withdrawRequest', payload);
    },
    deleteDeclined(uuid: string, enableButton: () => void) {
      const payload = {
        uuid,
        enableButton,
      };
      this.$store.dispatch('deleteDeclined', payload);
    },
    formatDate(datetime: Date) {
      const newDatetime = moment.utc(datetime, moment.ISO_8601, true).format('MM/DD/YYYY');
      return newDatetime;
    },
    persistentAlert(route: Array<string>, type: string, remove: boolean, message: string) {
      this.$store.commit('updatePersistentAlert', {
        route,
        type,
        remove,
        message,
      });
    },
    homeConflict(remove: boolean) {
      this.persistentAlert(['Home'], 'Conflict', remove, 'There are conflicts in your leave requests due to a recent schedule update, please resolve those conflicts by deleting the leave request and submitting new ones if you wish to. If you were not expecting a schedule update, please reach out to your HR Admin.');
    },
  },
  watch: {
    userLeaveRequestsData(val) {
      // Update commponent
      this.leaveRequestKey += 1;
      // Message if there is a leave with conflict status
      if (val && val.some((leave: any) => leave.status === 'conflict')) {
        this.homeConflict(false);
      } else {
        this.homeConflict(true);
      }
    },
    userLeaveHistoryData() {
      // Update commponent
      this.leaveHistoryKey += 1;
    },
  },
})
export default class Home extends Vue {}
