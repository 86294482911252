
import { Options, Vue } from 'vue-class-component';
import ManageSingleEmployee from '@/views/ManageSingleEmployee.vue';
import UserInfo from '@/components/UserInfo.vue';
import NewRequest from '@/views/NewRequest.vue';
import NewRequestButton from '@/components/NewRequestButton.vue';
import LeaveItem from '@/views/LeaveItem.vue';
import LeaveBalance from '@/views/LeaveBalance.vue';
import Button from '@/components/Button.vue';
import List from '@/components/List.vue';

import moment from 'moment';

@Options({
  name: 'View/Edit User',
  inheritAttrs: false,
  components: {
    ManageSingleEmployee,
    UserInfo,
    NewRequest,
    NewRequestButton,
    LeaveItem,
    LeaveBalance,
    Button,
    List,
  },
  props: ['userInfoData'],
  data() {
    return {
      tab: 'search',
      edit: null,
      uin: null,
      title: '',
      userWithDays: false,
      formDetails: {
        employeeStatus: 'active',
        holidayGroup: null,
        fte: 1,
        primarySupervisor: '',
        secondarySupervisor: '',
        calendarsList: [],
      },
      formDetailsErrors: {
        employeeStatus: null,
        holidayGroup: null,
        fte: null,
        primarySupervisor: null,
        secondarySupervisor: null,
        calendarsList: null,
      },
      newSchedule: false,
      disableSchedule: false,
      scheduleRangeDisplay: null,
      formSchedule: {
        selectedSchedule: null, /* 'new' -or- uuid */
        scheduleStartDate: null,
        week: [{ day: 'sunday', hours: '' }, { day: 'monday', hours: '' }, { day: 'tuesday', hours: '' }, { day: 'wednesday', hours: '' }, { day: 'thursday', hours: '' }, { day: 'friday', hours: '' }, { day: 'saturday', hours: '' }],
      },
      supervisorError: false,
      altSupervisorError: false,
      submitType: null,
      submitted: false,
    };
  },
  created() {
    if (this.$route.name === 'Add User') {
      this.title = 'Add User';
      this.edit = false;
      this.newSchedule = true;
    } else if (this.$route.name === 'View/Edit User') {
      this.title = 'View/Edit User';
      this.edit = true;
      this.newSchedule = false;
    } else {
      this.$router.push('/hr-admin');
      this.$store.commit(
        'updateError',
        'There seems to be an error.',
      );
    }
  },
  computed: {
    userLookUpData() {
      return this.$store.state.lookUpUserInfoData;
    },
    userScheduleData() {
      return this.$store.state.userScheduleData;
    },
    checkScheduleData() {
      return this.$store.state.checkScheduleData;
    },
    checkUserData() {
      return this.$store.state.checkUserData;
    },
    getCalendarsData() {
      return this.$store.state.calendarsData || [];
    },
    cantEditOwn() {
      const check = this.uin === this.userInfoData.uin || false;
      // if (check) {
      //   this.$store.commit(
      //     'updateWarning',
      //     'Updating is disabled, cannot update your own user.',
      //   );
      // }
      return check;
    },
    parseSupervisorSummary() {
      let uin = '';
      let name = '';
      let altUin = '';
      let altName = '';
      this.supervisorError = false;
      this.altSupervisorError = false;
      if (!this.formDetails.primarySupervisor) {
        uin = 'Field Left Empty';
        name = 'Field Left Empty';
      } else if (this.formDetails.primarySupervisor
      && this.checkUserData.supervisorExistsBanner === true
      && this.checkUserData.supervisorExistsLdap === true) {
        uin = this.formDetails.primarySupervisor;
        name = this.checkUserData.supervisorName;
      } else if (this.formDetails.primarySupervisor
      && this.checkUserData.supervisorExistsBanner === false
      && this.checkUserData.supervisorExistsLdap === true) {
        uin = `${this.formDetails.primarySupervisor} Does not exist in Banner`;
        name = 'Does not exist in Banner';
        this.supervisorError = true;
      } else if (this.formDetails.primarySupervisor
      && this.checkUserData.supervisorExistsBanner === true
      && this.checkUserData.supervisorExistsLdap === false) {
        uin = `${this.formDetails.primarySupervisor} Does not exist in Active Directory`;
        name = 'Does not exist in Active Directory';
        this.supervisorError = true;
      } else if (this.formDetails.primarySupervisor
      && this.checkUserData.supervisorExistsBanner === false
      && this.checkUserData.supervisorExistsLdap === false) {
        uin = `${this.formDetails.primarySupervisor} Does not exist in Active Directory or Banner`;
        name = 'Does not exist in Active Directory or Banner';
        this.supervisorError = true;
      }
      // Alt
      if (!this.formDetails.secondarySupervisor) {
        altUin = 'Field Left Empty';
        altName = 'Field Left Empty';
      } else if (this.formDetails.secondarySupervisor
      && this.checkUserData.altSupervisorExistsBanner === true
      && this.checkUserData.altSupervisorExistsLdap === true) {
        altUin = this.formDetails.secondarySupervisor;
        altName = this.checkUserData.altSupervisorName;
      } else if (this.formDetails.secondarySupervisor
      && this.checkUserData.altSupervisorExistsBanner === false
      && this.checkUserData.altSupervisorExistsLdap === true) {
        altUin = `${this.formDetails.secondarySupervisor} Does not exist in Banner`;
        altName = 'Does not exist in Banner';
        this.altSupervisorError = true;
      } else if (this.formDetails.secondarySupervisor
      && this.checkUserData.altSupervisorExistsBanner === true
      && this.checkUserData.altSupervisorExistsLdap === false) {
        altUin = `${this.formDetails.secondarySupervisor} Does not exist in Active Directory`;
        altName = 'Does not exist in Active Directory';
        this.altSupervisorError = true;
      } else if (this.formDetails.secondarySupervisor
      && this.checkUserData.altSupervisorExistsBanner === false
      && this.checkUserData.altSupervisorExistsLdap === false) {
        altUin = `${this.formDetails.secondarySupervisor} Does not exist in Active Directory or Banner`;
        altName = 'Does not exist in Active Directory or Banner';
        this.altSupervisorError = true;
      }
      return {
        uin,
        name,
        altUin,
        altName,
      };
    },
  },
  methods: {
    lookUpUser(uin: number) {
      const lookupQuery = this.edit ? 'edit' : 'add';
      const userEndpoint = () => this.$store.dispatch('userInfo', { uin, lookupQuery });
      const calendarsEndpoint = () => this.$store.dispatch('getCalendars', { uin });
      if (this.uin.toString().length === 9 && this.edit === false) {
        userEndpoint();
        this.formSchedule.selectedSchedule = 'new';
      } else if (this.uin.toString().length === 9 && this.edit === true) {
        userEndpoint();
        calendarsEndpoint();
      } else {
        this.$store.commit(
          'updateWarning',
          'UIN must be 9 characters long.',
        );
      }
    },
    calcSchedule(hours: number, fte: string) {
      const fteParsed = parseInt(fte, 10);
      if (fteParsed === 1) {
        const singleDay = hours / 5;
        this.formSchedule.week[0].hours = 0;
        for (let i = 1; i < this.formSchedule.week.length - 1; i += 1) {
          this.formSchedule.week[i].hours = singleDay.toString();
        }
        this.formSchedule.week[6].hours = 0;
      } else {
        this.resetScheduleAlert();
        this.$store.commit(
          'updateWarning',
          'This feature is disabled, the Employee\'s FTE is not 100%.',
        );
      }
    },
    calculatePercentage(fte: number) {
      return (fte * 100).toFixed(1);
    },
    fteChange() {
      if (this.edit === true) {
        this.changeScheduleAlert();
      } else {
        this.resetSchedule();
      }
    },
    initializeNewSchedule() {
      let hoursValue = '';
      if (this.userWithDays) {
        hoursValue = '8';
      }
      this.formSchedule.week = [{ day: 'sunday', hours: hoursValue }, { day: 'monday', hours: hoursValue }, { day: 'tuesday', hours: hoursValue }, { day: 'wednesday', hours: hoursValue }, { day: 'thursday', hours: hoursValue }, { day: 'friday', hours: hoursValue }, { day: 'saturday', hours: hoursValue }];
    },
    changeScheduleAlert() {
      this.initializeNewSchedule();
      this.$store.commit(
        'updateWarning',
        'Must create a new schedule if the FTE changes.',
      );
    },
    resetScheduleAlert() {
      this.initializeNewSchedule();
      this.$store.commit(
        'updateWarning',
        'Schedule Reset.',
      );
    },
    changeSchedule(schedule: string) {
      if (schedule === 'new') {
        this.newSchedule = true;
        this.disableSchedule = false;
        this.initializeNewSchedule();
      } else if (schedule !== 'new' && schedule && schedule.length === 36) {
        this.newSchedule = false;
        this.disableSchedule = true;
        const uuid = schedule;
        this.initializeExistingSchedule(uuid);
      }
    },
    initializeExistingSchedule(uuid: string) {
      Object.keys(this.userScheduleData).forEach((value) => {
        if (this.userScheduleData[value].uuid === uuid) {
          this.formSchedule.week = [];
          const schedule: { day: string, hours: number }[] = [];
          this.formSchedule.week.push({ day: 'sunday', hours: this.userScheduleData[value].sunday });
          this.formSchedule.week.push({ day: 'monday', hours: this.userScheduleData[value].monday });
          this.formSchedule.week.push({ day: 'tuesday', hours: this.userScheduleData[value].tuesday });
          this.formSchedule.week.push({ day: 'wednesday', hours: this.userScheduleData[value].wednesday });
          this.formSchedule.week.push({ day: 'thursday', hours: this.userScheduleData[value].thursday });
          this.formSchedule.week.push({ day: 'friday', hours: this.userScheduleData[value].friday });
          this.formSchedule.week.push({ day: 'saturday', hours: this.userScheduleData[value].saturday });
          // Used for displaying the week date range in places like Summary screen
          this.scheduleRangeDisplay = `${this.userScheduleData[value].start_date || ''} ${this.userScheduleData[value].start_date ? '-' : ''} ${this.userScheduleData[value].end_date || 'Latest'}`;
        }
      });
    },
    async updateUser(uin: string, check: boolean) {
      this.tab = 'user-update-summary';
      const payload = {
        uin,
        status: this.formDetails.employeeStatus,
        primarySupervisorUin: this.formDetails.primarySupervisor,
        secondarySupervisorUin: this.formDetails.secondarySupervisor,
        appointment: this.formDetails.fte,
        holidayGroup: this.formDetails.holidayGroup,
        check, // Parameter only used to determine URL in axios call; to check for errors
      };
      try {
        await this.$store.dispatch('updateUser', payload);
        if (!check) this.submitCalendars(uin);
      } catch (error) {
        // nothing
      }
    },
    async submitUser(uin: string, check: boolean) {
      if (this.userWithDays) {
        this.initializeNewSchedule();
        this.formDetails.fte = 1;
        this.formSchedule.scheduleStartDate = String(moment().format('YYYY-MM-DD'));
      }
      if (check) this.tab = 'user-submit-summary';
      if ((typeof this.formDetails.secondarySupervisor) === 'string'
      && this.formDetails.secondarySupervisor.length === 0) {
        this.formDetails.secondarySupervisor = null;
      }
      const payload = {
        uin,
        status: this.formDetails.employeeStatus,
        primarySupervisorUin: this.formDetails.primarySupervisor,
        secondarySupervisorUin: this.formDetails.secondarySupervisor,
        appointment: this.formDetails.fte,
        holidayGroup: this.formDetails.holidayGroup,
        check, // Parameter only used to determine URL in axios call; to check for errors
      };
      try {
        await this.$store.dispatch('submitUser', payload);
        if (!check) this.submitSchedule(uin, false);
        if (!check) this.submitCalendars(uin);
      } catch (error) {
        // nothing
      }
    },
    submitCalendars(uin: string) {
      const liveList = this.getCalendarsData;
      const updatedCalendarsList = this.formDetails.calendarsList;
      const addList = updatedCalendarsList.filter(
        (a: any) => !liveList.some(
          (b: any) => b === a,
        ),
      );
      const removeList = liveList.filter(
        (a: any) => !updatedCalendarsList.some(
          (b: any) => b === a,
        ),
      );
      if (removeList.length > 0) this.$store.dispatch('removeCalendars', { uin, calendars: removeList });
      if (addList.length > 0) this.$store.dispatch('addCalendars', { uin, calendars: addList });
    },
    submitSchedule(uin: string, check: boolean) {
      if (check) this.tab = 'schedule-submit-summary';
      const payload = {
        uin,
        startDate: this.formSchedule.scheduleStartDate,
        days: this.formSchedule.week,
        check, // Parameter only used to determine URL in axios call; to check leave conflicts
      };
      this.$store.dispatch('submitSchedule', payload);
    },
    updateSchedule(uin: string, uuid: string, check: boolean) {
      this.tab = 'schedule-update-summary';
      const payload = {
        uin,
        uuid,
        days: this.formSchedule.week,
        check, // Parameter only used to determine URL in axios call; to check leave conflicts
      };
      this.$store.dispatch('updateSchedule', payload);
    },
    unlockSchedule() {
      if (this.cantEditOwn === false) {
        this.disableSchedule = !this.disableSchedule;
      }
    },
    submitForm(submitType: string) {
      const sharedCalendarValidation = () => {
        let cont = true;
        if (this.$refs.calendarsList.current) {
          this.$store.commit(
            'updateError',
            'Shared Calendar Assignment List is entered but not added to the list.',
          );
          cont = false;
        }
        return cont;
      };
      switch (submitType) {
        case 'submit-user-check':
          this.submitUser(this.uin, true);
          break;
        case 'submit-user':
          this.submitted = true;
          if (this.supervisorError === false && this.altSupervisorError === false) {
            this.submitUser(this.uin, false);
          } else {
            this.$store.commit(
              'updateError',
              'Please resolve the errors in your form.',
            );
          }
          break;
        case 'update-user-check':
          if (sharedCalendarValidation()) {
            this.updateUser(this.uin, true);
          }
          break;
        case 'update-user':
          this.submitted = true;
          if (this.supervisorError === false && this.altSupervisorError === false) {
            this.updateUser(this.uin, false);
          } else {
            this.$store.commit(
              'updateError',
              'Please resolve the errors in your form.',
            );
          }
          break;
        case 'submit-schedule-check':
          this.submitSchedule(this.uin, true);
          break;
        case 'submit-schedule':
          this.submitted = true;
          this.submitSchedule(this.uin, false);
          break;
        case 'update-schedule-check':
          this.updateSchedule(this.uin, this.formSchedule.selectedSchedule, true);
          break;
        case 'update-schedule':
          this.submitted = true;
          this.updateSchedule(this.uin, this.formSchedule.selectedSchedule, false);
          break;
        case 'schedule':
          if (sharedCalendarValidation()) {
            this.tab = 'schedule';
          }
          break;
        case 'details':
          this.tab = 'details';
          break;
        default:
          break;
      }
    },
    calendarsListChanged(list: Array<string>) {
      this.formDetails.calendarsList = list;
    },
  },
  watch: {
    userLookUpData(val) {
      if (this.edit === true && this.tab === 'search') {
        if (val.appointment || val.holiday_group || val.supervisor_name) {
          const payload = {
            uin: val.uin,
            all: true,
          };
          this.$store.dispatch('userSchedule', payload);
          this.tab = 'details';
          this.formDetails.fte = val.appointment;
          this.formDetails.employeeStatus = val.status;
          this.formDetails.holidayGroup = val.holiday_group;
          this.formDetails.primarySupervisor = val.supervisor_uin;
          if (val.alt_supervisor_uin) {
            this.formDetails.secondarySupervisor = val.alt_supervisor_uin;
          }
          this.disableSchedule = true;
        }
      } else if (this.edit === false && this.tab === 'search') {
        if (!val.error) {
          this.tab = 'details';
          this.disableSchedule = false;
        }
      }
      if (val.leaves_value_type === 'Days') {
        this.userWithDays = true;
      }
    },
    userScheduleData(val) {
      // Get latest schedule when on Users' View/Edit Schedule page
      Object.keys(this.userScheduleData).forEach((value) => {
        // Latest schedule does not have a end_date
        // Fruther improvement can be done by also checking latest start_date
        if (this.userScheduleData[value].end_date === null) {
          this.formSchedule.selectedSchedule = this.userScheduleData[value].uuid;
        }
      });
      this.initializeExistingSchedule(this.formSchedule.selectedSchedule);
    },
    '$store.state.hrUserTabData': {
      immediate: true,
      handler(val) {
        if (val) {
          this.tab = val;
          this.$store.commit('updateHrUserTab', null);
        }
      },
    },
    tab: {
      immediate: true,
      handler(val, oldVal) {
        if (this.tab === 'leaves') {
          this.$router.push(`/hr-admin/user/${this.uin}`);
        } else if (oldVal === 'new-request' && val !== 'new-request') {
          const payload = {
            uin: this.uin,
            all: true,
          };
          this.$store.dispatch('userSchedule', payload);
        }
      },
    },
    getCalendarsData(val) {
      if (val && !val.some((a: Array<string>) => this.formDetails.calendarsList.includes(a))) {
        this.formDetails.calendarsList = this.formDetails.calendarsList.concat(val);
      }
    },
    'userLookUpData.validationErrors': {
      handler(val) {
        if (val && val.length > 0) {
          val.forEach((alert: any) => {
            this.$store.commit('updateError', alert.message);
            switch (alert.field) {
              case 'supervisor_uin':
                this.formDetailsErrors.primarySupervisor = alert.message;
                break;
              case 'alt_supervisor_uin':
                this.formDetailsErrors.secondarySupervisor = alert.message;
                break;
              default:
                // nothing
            }
          });
        }
      },
    },
  },
  beforeRouteLeave(to, from) {
    let resolve;
    if ((this.tab === 'user-update-summary'
    || this.tab === 'user-submit-summary'
    || this.tab === 'schedule-submit-summary'
    || this.tab === 'schedule-update-summary')
    && this.submitted !== true) {
      const answer = window.confirm('Do you really want to leave? you have unsaved changes!');
      if (!answer) {
        resolve = false;
      } else {
        resolve = true;
      }
    }
    return resolve;
  },
})
export default class Holidays extends Vue {}
